import {removeAllFromClass, getElementOrParentByClass} from '../helpers/dom-helpers'
const switcherClass = 'js-lang-switcher'
const activeClass = 'is-active'

document.addEventListener('click', function(e) {
    removeAllFromClass(switcherClass, activeClass)
    let domElem = getElementOrParentByClass(e, switcherClass);

    if (domElem) {
        domElem.classList.add(activeClass)
    }
})

document.addEventListener('scroll', function(e) {
    removeAllFromClass(switcherClass, activeClass)
})